import React, { useEffect, useState } from "react";
import {
  Grid,
  Button,
  Card,
  CardContent,
  TableCell,
  TableRow,
  Dialog,
  DialogTitle,
  TextField,
  DialogActions,
  DialogContent,
  Slide,
} from "@mui/material";
import { useDispatch } from "react-redux";
import { addusertran, updateuserdata } from "../service";
import { enumReducerType } from "../common/enumReducerTypes";
import { useNavigate } from "react-router-dom";
import "./organism.css";
import { Callto } from "../common/Callto";
import { Mailto } from "../common/Emailto";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import axios from "axios";

const ITEM_HEIGHT = 48;

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export const AllUsersItem = ({ item, options, selectedoption, getData }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [statuslabel, setStatusLabel] = useState("");
  const [opens, setOpen] = React.useState(false);
  const currentDate = new Date();
  currentDate.setDate(currentDate.getDate() + 1);
  const tomorrowDate = currentDate.toISOString().split("T")[0];
  const [followupDate, setFollowupDate] = useState(tomorrowDate);
  const [remarks, setRemarks] = useState("");
  const [optid, setOptid] = useState("");

  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const currSelectedOpt = options.filter(
    (curr) => selectedoption === curr.value
  )[0];

  const dispatch = useDispatch();

  const navigate = useNavigate();

  const handleAccept = (item) => {
    console.log("item data:" + item);
    const type = enumReducerType.Curr_User_Data;
    const payload = item;
    const action = {
      type,
      payload,
    };

    dispatch(action);
    navigate("/addsubs");
  };

  const handleChangeOpt = async (elem) => {
    setOptid(elem);
    const id = item.userid;
    const statusid = elem;
    const data = {
      statusid,
    };
    const userPayload = {
      id,
      data,
    };
    try {
      const result = await updateuserdata(userPayload);
      handleClose();
      getData();
    } catch (e) {
      console.log("error while update status-->", e);
    }
  };

  useEffect(() => {
    const results = options.find((elem) => elem.value.includes(item.statusid));
    setStatusLabel(results);
  }, [options, item.statusid]);

  useEffect(() => {
    if (optid === "649aad4aac92af02a4dc7fd2") {
      setOpen(true);
    } else {
      setOpen(false);
    }
  }, [options, open]);

  const handleDateChange = (event) => {
    const selectedValue = event.target.value;
    if (selectedValue >= tomorrowDate) {
      setFollowupDate(selectedValue);
    }
  };

  const handleRemainder = async (userid) => {
    const statusid = "649aad4aac92af02a4dc7fd2";
    const payload = {
      userid,
      remarks,
      followupDate,
      statusid,
    };
    const result = await addusertran(payload);
    result.status === 200 && setOpen(false);
  };

  return (
    <>
      <TableRow
        style={{
          marginTop: "10px",
          marginBottom: "10px",
          boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
        }}
        key={item.name}
        sx={{
          "&:last-child td, &:last-child th": { border: 0 },
        }}
      >
        <TableCell component="th" scope="row" align="center">
          {item.name}
        </TableCell>
        <TableCell align="center">+91 {item.mobile}</TableCell>
        <TableCell align="center">{item.email}</TableCell>
        <TableCell align="left">
          {statuslabel.label}
          <IconButton
            sx={{ float: "right" }}
            aria-label="more"
            id="long-button"
            aria-controls={open ? "long-menu" : undefined}
            aria-expanded={open ? "true" : undefined}
            aria-haspopup="true"
            onClick={handleClick}
          >
            <MoreVertIcon />
          </IconButton>
          <Menu
            id="long-menu"
            MenuListProps={{
              "aria-labelledby": "long-button",
            }}
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            PaperProps={{
              style: {
                maxHeight: ITEM_HEIGHT * 4.5,
                width: "20ch",
              },
            }}
          >
            {options.map((item, index) => (
              <MenuItem
                key={index}
                selected={item.label === "New"}
                onClick={() => handleChangeOpt(item.value)}
              >
                {item.label}
              </MenuItem>
            ))}
          </Menu>
        </TableCell>
        <TableCell align="center">
          {" "}
          <Button
            onClick={() => handleAccept(item)}
            variant="contained"
            className="button-all-clr"
          >
            submit
          </Button>
        </TableCell>
      </TableRow>

      <Dialog
        open={opens}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>{"Follow up call"}</DialogTitle>
        <DialogContent>
          <TextField
            value={followupDate}
            onChange={handleDateChange}
            min={tomorrowDate}
            max="2025-12-31"
            fullWidth
            variant="outlined"
            type="date"
          />
          <br />
          <br />
          <TextField
            onChange={(e) => setRemarks(e.target.value)}
            fullWidth
            label="Description"
            multiline
            rows={4}
            value={remarks}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleRemainder(item.userid)}>Submit</Button>
          <Button onClick={() => setOpen(false)}>Cancel</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
