import React, { useEffect, useState } from "react";
import {
  Grid,
  Button,
  DialogTitle,
  Slide,
  Dialog,
  DialogContent,
  Typography,
  DialogActions,
  TextField,
} from "@mui/material";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import Select from "react-select";
import { allbiz } from "../service";
import { alltrans } from "../service/addtransaction/alltrans";
import { NestedListTrans } from "./NestedListTrans";
import { enumReducerType } from "../common/enumReducerTypes";
import PendingActionsIcon from "@mui/icons-material/PendingActions";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export const AllTransaction = () => {
  const [data, setData] = useState([]);
  const [options, setOptions] = useState([]);
  const [bizs, Setbizs] = useState(null);

  const dispatch = useDispatch();
  const selCurrSubscriber = JSON.parse(sessionStorage.getItem("currItem"));
  const navigate = useNavigate();

  const gettrans = async () => {
    const allBusinesses = await allbiz();
    const filtered = allBusinesses
      .filter((item) => selCurrSubscriber.bizs.includes(item._id))
      .map((item) => {
        return { value: item._id, label: item.name };
      });
    setOptions(filtered);
    Setbizs(filtered[0]);
  };

  const setDataSelected = async () => {
    const alltransaction = await alltrans(selCurrSubscriber.subsid, bizs.value);
    setData(alltransaction);
  };

  const handleSelectedBiz = async (e) => {
    Setbizs(e);
  };

  const handleAddpayment = () => {
    const type = enumReducerType.PENDING_BALANCE;
    const payload = data.accsummary.totalBalance;
    const action = { type, payload };
    dispatch(action);
    navigate("/addtrans");
  };

  useEffect(() => {
    if (bizs) {
      const type = enumReducerType.CURR_SELECTED_BIZ;
      const payload = bizs;
      const action = { type, payload };
      dispatch(action);
      setDataSelected();
    }
  }, [bizs]);

  useEffect(() => {
    gettrans();
  }, []);
  return (
    <div style={{ margin: "-10px" }}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Grid container spacing={2} sx={{ padding: "5px" }}>
            <Grid item xl={4} lg={4} md={4} sm={12} xs={12}>
              <Typography variant="h5" align="center">
                {" "}
                {selCurrSubscriber.name} - {selCurrSubscriber.mobile}
              </Typography>
            </Grid>
            <Grid item xl={6} lg={6} md={6} sm={8} xs={8}>
              {bizs && (
                <Select
                  options={options}
                  defaultValue={bizs}
                  onChange={(e) => handleSelectedBiz(e)}
                />
              )}
            </Grid>

            <Grid item xl={2} lg={2} md={5} sm={3} xs={3}>
              <Button
                className="button-all-clr"
                variant="contained"
                fullWidth
                onClick={() => handleAddpayment()}
              >
                Add
              </Button>
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <NestedListTrans data={data} />
        </Grid>
      </Grid>
    </div>
  );
};
