import React, { useEffect, useState } from "react";
import {
  Grid,
  TextField,
  InputLabel,
  Button,
  Card,
  CardContent,
  Typography,
} from "@mui/material";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Select from "react-select";

import {
  allselectcurr,
  addtransaction,
  allselectpaymentmode,
} from "../service";

export const AddTransactions = () => {
  const [amt, setAmt] = useState("");
  const [transtype] = useState("DEBIT");
  const [error, setError] = useState("");
  const [remarks, setRemarks] = useState("");
  const [mode, setMode] = useState([]);
  const [selectedmode, setSelectedmode] = useState(null);
  const [currbiz, setCurrbiz] = useState({});
  const [options, setOptions] = useState([]);
  const [selectedoption, setSelectedoption] = useState(null);
  const selCurrBiz = useSelector((state) => state.subsReducer.currselectedbiz);
  const selCurrSubscriber = JSON.parse(sessionStorage.getItem("currItem"));
  const currentDate = new Date();
  currentDate.setDate(currentDate.getDate() + 1);
  const tomorrowDate = currentDate.toISOString().split("T")[0];
  const [followupDate, setFollowupDate] = useState(tomorrowDate);

  const pendingbalance = useSelector((state) => state.balanceReducer.balance);
  console.log(followupDate, "------------");
  const navigate = useNavigate();

  const getData = async () => {
    console.log("SELECTED BIZ====>", selCurrBiz);
    const allCurrency = await allselectcurr();
    setOptions(allCurrency);

    const allModes = await allselectpaymentmode();
    setMode(allModes);
  };

  const handleCancel = () => {
    navigate("/alltrans");
    setAmt(0);
    setRemarks("");
  };

  const validateAmt = (e) => {
    var amtExpre = /^(?:[1-9]\d*|\d)$/;
    if (amt.match(amtExpre)) {
      setAmt(amt);
      setError("");
      return true;
    } else {
      setError("Invalid Amount !!!!!");
      return false;
    }
  };
  useEffect(() => {
    if (pendingbalance === "") {
      navigate("/alltrans");
    }
  }, []);
  const handleSubmit = async (e) => {
    const res = await addtransaction(
      selCurrSubscriber.subsid,
      currbiz.value,
      amt,
      transtype,
      selectedmode.value,
      selectedoption.value,
      followupDate
    );
    navigate("/alltrans");
  };

  const handleDateChange = (event) => {
    const selectedValue = event.target.value;
    if (selectedValue >= tomorrowDate) {
      setFollowupDate(selectedValue);
    }
  };

  useEffect(() => {
    setCurrbiz(selCurrBiz);
    getData();
  }, []);

  useEffect(() => {
    const defaultMode = mode.length > 0 ? mode[0] : null;
    const defaultOption = options.length > 0 ? options[0] : null;

    setSelectedmode(defaultMode);
    setSelectedoption(defaultOption);
  }, [mode, options]);

  return (
    <div style={{ height: "100%", minHeight: "80vh" }}>
      <Card>
        <CardContent>
          <Grid container spacing={2}>
            <Grid
              item
              xs={12}
              md={6}
              sm={12}
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <div>
                <Typography variant="h4">
                  {selCurrSubscriber.name.toUpperCase()}
                </Typography>
                <Typography variant="h5">{selCurrSubscriber.mobile}</Typography>
                <Typography variant="h6">
                  Pending Balance -
                  <span style={{ color: "red" }}> Rs.{pendingbalance} </span>
                </Typography>
              </div>
            </Grid>

            <Grid item xs={12} md={6} sm={12}>
              <Grid container spacing={3}>
                <Grid item xs={12} md={12} sm={12} className="error">
                  {error}
                </Grid>
                <Grid item xs={12} md={12} sm={12}>
                  <TextField
                    size="small"
                    id="bizid"
                    disabled
                    value={selCurrBiz.label}
                    variant="outlined"
                    fullWidth
                    required
                    autoComplete="off"
                    InputProps={{
                      readOnly: true,
                    }}
                  />
                </Grid>
                <Grid item xs={12} md={12} sm={12}>
                  <TextField
                    id="amt"
                    type="number"
                    variant="outlined"
                    onChange={(e) => setAmt(e.target.value)}
                    label="Amount"
                    fullWidth
                    value={amt}
                    required
                    size="small"
                    autoComplete="off"
                  />
                </Grid>

                <Grid item xs={12} md={12} sm={12} className="liftup2">
                  <InputLabel>
                    <b>Payment Mode</b>
                  </InputLabel>
                  {mode && (
                    <Select
                      options={mode}
                      value={selectedmode}
                      onChange={(e) => setSelectedmode(e)}
                    />
                  )}
                </Grid>
                <Grid item xs={12} md={12} sm={12} className="liftup1">
                  <InputLabel>
                    <b>Currency Type</b>
                  </InputLabel>
                  {options && (
                    <Select
                      options={options}
                      value={selectedoption}
                      onChange={(e) => setSelectedoption(e)}
                    />
                  )}
                </Grid>

                <Grid item xs={12} md={12} sm={12}>
                  <TextField
                    id="remarks"
                    onChange={(e) => setRemarks(e.target.value)}
                    variant="outlined"
                    label="Remarks"
                    fullWidth
                    size="small"
                    value={remarks}
                    required
                    autoComplete="off"
                  />
                </Grid>
                <Grid item xs={12} md={12} sm={12}>
                  <TextField
                    value={followupDate}
                    onChange={handleDateChange}
                    min={tomorrowDate}
                    max="2025-12-31"
                    fullWidth
                    variant="outlined"
                    type="date"
                  />
                </Grid>
                <Grid item xs={12} md={6} sm={6}>
                  <Button
                    className="button-all-clr"
                    variant="contained"
                    fullWidth
                    onClick={handleSubmit}
                  >
                    Submit
                  </Button>
                </Grid>
                <Grid item xs={12} md={6} sm={6}>
                  <Button
                    className="button-all-clr-outlined{"
                    variant="outlined"
                    fullWidth
                    onClick={handleCancel}
                  >
                    Cancel
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </div>
  );
};
