import { postSecureAPI } from "../helper";
export const addtransaction = async (
  subsid,
  bizid,
  amount,
  transactiontype,
  modeofpayment,
  currencycode,
  followupDate
) => {
  const parentsubsid = sessionStorage.getItem("parentsubsid");
  const payload = {
    subsid,
    parentsubsid,
    bizid,
    amount,
    transactiontype,
    modeofpayment,
    currencycode,
    followupDate
  };
  const result = await postSecureAPI("/addtransaction", payload);
  return result;
};
