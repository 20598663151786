import React, { useState, useEffect } from "react";
import {
  Grid,
  InputLabel,
  TableCell,
  TextField,
  TableHead,
  TableRow,
  Table,
  TableContainer,
  Paper,
  Pagination,
  TableBody,
  Button,
} from "@mui/material";
import { useNavigate, Link } from "react-router-dom";
import SearchIcon from "@mui/icons-material/Search";
import Select from "react-select";

import { AllUsersItem } from "./AllUsersItem";
import { allusers } from "../service/users/allusers";
import { allstatus } from "../service/status/allstatus";
import "./organism.css";

export const AllUsers = () => {
  const navigate = useNavigate();
  const [options, setOptions] = useState([]);
  const [data, setData] = useState([]);
  const [origdata, setOrigdata] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);

  const getStatus = async () => {
    const allStatus = await allstatus();
    const options = allStatus.map((item) => {
      return { value: item.id, label: item.name };
    });
    setOptions(options);
    console.log(options, "----options-----");
  };

  const handleSearch = (searchtext) => {
    const filtereddata = origdata.filter(
      (item) =>
        (item.name &&
          item.name.toUpperCase().includes(searchtext.toUpperCase())) ||
        (item.mobile &&
          item.mobile.toString().toUpperCase().includes(searchtext)) ||
        (item.email &&
          item.email.toUpperCase().includes(searchtext.toUpperCase()))
    );
    setData(filtereddata);
  };

  const getData = async () => {
    const result = await allusers();

    const filtdata = result.filter(
      (item) => item.statusid !== "649aad53ac92af02a4dc7fd8"
    );

    setData(filtdata);

    setOrigdata(filtdata);
    // const sorteddata = result.sort(function (a, b) {
    //   return a.name - b.name;
    // });
    // setData(sorteddata);
    // setOrigdata(sorteddata);
  };

  useEffect(() => {
    const token = sessionStorage.getItem("token");
    !token && navigate("/signin");
    getData();
    getStatus();
  }, []);

  const handlePageChange = (event, page) => {
    setCurrentPage(page);
  };

  const productsPerPage = 4;
  const totalPages = Math.ceil(data.length / productsPerPage);
  const startIndex = (currentPage - 1) * productsPerPage;
  const endIndex = startIndex + productsPerPage;
  const Data = data.slice(startIndex, endIndex);

  

  return (
    <div>
      <Grid container spacing={2}>
        <Grid item xl={9} lg={9} md={9} sm={12} xs={12}>
          <TextField
            fullWidth
            variant="outlined"
            placeholder="Search by Customer Name / Mobile Number"
            onChange={(e) => handleSearch(e.target.value)}
            size="small"
          />
        </Grid>
        <Grid item xl={2} lg={2} md={2} sm={6} xs={6}>
          <Button
            variant="contained"
            className="button-all-clr"
            fullWidth
            endIcon={<SearchIcon />}
            onClick={() => navigate("/reports")}
          >
            Search
          </Button>
        </Grid>
        <Grid item xl={1} lg={1} md={1} sm={6} xs={6}>
          <Button
            fullWidth
            className="button-all-clr-outline"
            variant="outlined"
          >
            reset
          </Button>
        </Grid>

        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
          {" "}
          <TableContainer
            component={Paper}
            sx={{ boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)" }}
          >
            <Table
              sx={{
                minWidth: 650,
                borderCollapse: "separate",
                borderSpacing: "0px 4px",
              }}
              aria-label="simple table"
            >
              <TableHead>
                <TableRow
                  style={{
                    marginTop: "20px",
                    marginBottom: "20px",
                    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                  }}
                >
                  <TableCell className="content-tabel-head" align="center">
                    Name
                  </TableCell>
                  <TableCell className="content-tabel-head" align="center">
                    Mobile Number
                  </TableCell>
                  <TableCell className="content-tabel-head" align="center">
                    Email
                  </TableCell>
                  <TableCell className="content-tabel-head" align="center">
                    Status
                  </TableCell>
                  <TableCell className="content-tabel-head" align="center">
                    Action
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {Data.map((item) => (
                  <AllUsersItem
                    item={item}
                    options={options}
                    selectedoption={item.statusid}
                    getData={getData}
                  />
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>

        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
          <Pagination
            count={totalPages}
            page={currentPage}
            onChange={handlePageChange}
            color="secondary"
            variant="outlined"
          />
        </Grid>
      </Grid>
    </div>
  );
};
